.nav-drop > ul {
    display: flex;
    flex-flow: row no-wrap;

    text-align: right;
    font-size: 0;
    line-height: 0;
    margin-bottom: 0;
    letter-spacing: -4px; 
  }

  .nav-drop > ul > li {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 10px;
  }
  .nav-drop > ul > li > a {
    color: white;
  }
  .nav-drop > ul > li > a * {
    color: white;
  }
  .nav-drop ul a {
    -webkit-transition: color .3s linear;
    transition: color .3s linear;
  }
  .nav-drop ul a:hover {
    text-decoration: none;
    color: #00a9ff;
  }
  .resize-active .nav-drop,
  .resize-active .nav-drop ul {
    -webkit-transition: none !important;
    transition: none !important;
  }
  .nav-drop ul li.active a {
    color: white;
  }