.criipto-stripe-base {
    font-size: 16px;
    font-family: "Raleway", sans-serif;
    border-radius: 2px;
    width: 100%;
    padding: 11px 15px;
    border: 0;
    margin-right: 10px;
    background-color: white;
}

.form-group {
	margin-bottom: 12px;
}

.form-group.has-error .form-control,
.form-group.has-error .selectric {
	border-color: #ff3352;
}

.form-group.has-error .form-error-message {
	display: block;
}

.form-label {
	padding: 1px 0;
	margin: 0;
	font-weight: normal;
}

.form-control {
	height: 40px;
	padding: 8px 16px;
	border: 2px solid white;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 16px;
	line-height: 19px;
	transition: border-color 0.2s ease;
}

.form-control:focus {
	border-color: #30b7fc;
}

.form-control::-webkit-input-placeholder {
	color: rgba(45, 62, 80, 0.5);
	transition: opacity 0.3s ease;
	opacity: 1;
}

.form-control::-moz-placeholder {
	color: rgba(45, 62, 80, 0.5);
	transition: opacity 0.3s ease;
	opacity: 1;
}

.form-control:-moz-placeholder {
	color: rgba(45, 62, 80, 0.5);
	transition: opacity 0.3s ease;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: rgba(45, 62, 80, 0.5);
	transition: opacity 0.3s ease;
	opacity: 1;
}

.form-control:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset;
}

.form-control:focus::-webkit-input-placeholder {
	opacity: 0;
}

.form-control:focus::-moz-placeholder {
	opacity: 0;
}

.form-control:focus:-moz-placeholder {
	opacity: 0;
}

.form-control:focus:-ms-input-placeholder {
	opacity: 0;
}

p.form-control {
	background-color: inherit;
    border: inherit;
}

.form-error-message {
	padding: 7px 14px;
	display: none;
	font-size: 14px;
	line-height: 16px;
	color: white;
	background: #ff3352;
	user-select: none;
	cursor: default;
}

.form .btn {
	padding: 10px;
	border: none;
	border-radius: 2px;
	font-size: 16px;
	font-weight: 200;
	line-height: normal;
	letter-spacing: .03em;
	transition: background-color 0.2s ease;
}

.form .btn-primary {
	background: #2d3e50;
}

.form .btn-primary:hover,
.form .btn-primary:focus {
	background: #30b7fc;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-signup {
	max-width: 400px;
	padding: 16px 20px 28px;
	margin: 0 auto;
	background-color: rgba(45, 62, 80, 0.04);
}

@media (min-width: 992px) {
	.form-signup {
		max-width: none;
		padding: 32px 40px 56px;
	}
}

.form-signup .heading {
	margin: 0 0 28px;
	font-size: 26px;
	font-weight: normal;
	line-height: normal;
	text-align: center;
}

@media (min-width: 992px) {
	.form-signup .heading {
		margin-bottom: 48px;
		font-size: 40px;
	}
}

.form-signup .row {
	margin-left: -4px;
	margin-right: -4px;
}

.form-signup .form-col {
	padding-left: 4px;
	padding-right: 4px;
}

.form-signup .btn-submit {
	width: 100%;
	height: 48px;
	padding: 15px;
	margin-top: 40px;
	font-size: 16px;
	line-height: 19px;
	display: block;
}

.form-signup .error-text {
	color: #ff3352;
}



/* end form signup */