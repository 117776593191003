.plan {
	max-width: 240px;
	padding: 10px 10px 68px;
	border: 1px solid rgba(45, 62, 80, 0.1);
	border-radius: 2px;
	margin: 0 auto 20px;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #7b463d;
	position: relative;
	background: white;
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
}

@media (min-width: 750px) {
	.plan {
		min-height: 365px;
		max-width: none;
		margin-bottom: 0;
	}
}

@media (min-width: 1200px) {
	.plan {
		min-height: 390px;
		font-size: 14px;
		line-height: 18px;
	}
}

.verify-dashboard .plan {
	background: white;
}

.plan-col {
	padding-right: 5px;
	padding-left: 5px;
}

.plan * {
	color: inherit;
}

.plan p {
	margin: 0;
	font-size: inherit;
	line-height: inherit;
}

.plan .area {
	padding: 10px;
	color: inherit;
}

@media (min-width: 1200px) {
	.plan .area {
		padding-top: 25px;
	}
}

.plan .title {
	padding: 15px 10px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid rgba(45, 62, 80, 0.1);
	margin: -5px -10px 0;
	font-size: 18px;
	line-height: 24px;
}

@media (min-width: 992px) {
	.plan .title {
		font-size: 22px;
		line-height: 30px;
	}
}

.plan .price {
	margin-bottom: 14px;
	font-size: 32px;
	line-height: 42px;
	white-space: nowrap;
}

@media (min-width: 750px) {
	.plan .price {
		font-size: 36px;
	}
}

@media (min-width: 1200px) {
	.plan .price {
		font-size: 52px;
	}
}

.plan .price .currency {
	margin: 0px -3px 0 3px;
	font-size: 18px;
}

@media (min-width: 750px) {
	.plan .price .currency {
		font-size: 16px;
	}
}

@media (min-width: 1200px) {
	.plan .price .currency {
		font-size: 20px;
	}
}

.plan .big {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 20px;
}

@media (min-width: 1200px) {
	.plan .big {
		font-size: 18px;
		line-height: 22px;
	}
}

.plan .big:first-child {
	margin-top: 48px;
	margin-bottom: 24px;
}

@media (min-width: 1200px) {
	.plan .big:first-child {
		margin-top: 63px;
	}
}

.plan .foot {
	padding: 10px;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
}

.plan .foot .btn {
	width: 100%;
	height: 48px;
	padding: 15px;
	border: none;
	border-radius: 2px;
	display: block;
	color: white;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: .02em;
	background: #642e25;
	transition: background-color .3s ease;
}

.plan .foot .btn:hover,
.plan .foot .btn:focus {
	background: #7b463d;
}

.plan .check {
	width: 48px;
	height: 48px;
	margin: 0 auto;
	display: block;
	position: relative;
	top: -10px;
	background: url("/images/check-48x48.svg") no-repeat center center; 
}

.plan.selected {
    box-shadow: 5px 5px lightgrey;
}

.plan.selected .foot .btn {
	background-color: grey;
	cursor: default;
}
