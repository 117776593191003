.invoices .container {
    max-width: 900px;
}

.invoices .amount {
    text-align: right;
    padding-right: 50px;
    font-size: 16px;
    font-weight: 500;
}

.invoices .list-header, .list-item {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.list-header div, .list-header div.amount {
    font-weight: 600;

}

.list-footer {
    margin-top: 30px;
}

.invoiceitem {
    border-top: 1px solid #bfb8bb;
    padding: 10px 0 10px 0;
}

.invoiceitem img {
    margin-top: -10px;
    margin-bottom: -10px;
    height: 30px;
}

a {
    color: #00a9ff;
}

.previous span {
    text-align: left;
}

.next span {
    text-align: right;
}

.previous span a, .next span a {
    font-weight: 600;
    font-size: 16px;
}


a:hover, a:focus, a:active {
    color: #ff7058;
    outline: none;
    text-decoration: none;
}

.note {
    text-align: center;
    margin-top: -28px;
    margin-bottom: 28px;
}

 
