.nav-tabs-rounded {
    border-bottom: none;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
}

.nav-tabs-rounded > li {
    padding-left: 0;
    margin-bottom: 0;
    white-space: nowrap;
}
.nav-tabs-rounded > li:before {
    display: none !important;
}
.nav-tabs-rounded > li + li {
    margin-left: -1px;
}
.nav-tabs-rounded > li > a {
    min-width: 100px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    color: #2d3e50;
    line-height: 18px;
    letter-spacing: .02em;
    background-color: #e6e6e6;
}
@media (min-width: 375px) {
    .nav-tabs-rounded > li > a {
        min-width: 115px;
        padding-right: 30px;
        padding-left: 30px;
   }
}
.nav-tabs-rounded > li > a:hover, .nav-tabs-rounded > li > a:focus {
    border: 1px solid #e6e6e6;
    color: #2d3e50;
    background: white;
}
.nav-tabs-rounded > li:first-child > a {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.nav-tabs-rounded > li:last-child > a {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.nav-tabs-rounded > li.active > a {
    border: 1px solid #e6e6e6;
    background: white;
    font-weight: bold;
}

