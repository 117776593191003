.currency-selector .caret {
    width: 11px;
    height: 11px;
    margin: auto;
    border: none;
    border-top: 1px solid #2d3e50;
    border-right: 1px solid #2d3e50;
    display: block;
    position: absolute;
    top: -7px;
    right: 10px;
    bottom: 0;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    transition: border-color .2s ease;
}

.currency-selector .btn-default {
    padding: 11px 40px 11px 25px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    top: 2px;
    color: #2d3e50;
    background: white;
    transition: color .2s ease, background-color .3s ease;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.currency-selector .btn-default:hover {
    background-color: #e6e6e6;
}

.currency-selector .dropdown-menu {
    left: auto;
    right: 0;
}