.dashboard-nav ul li {
    display: block;
    float: left;
}
.dashboard-nav ul li a {
    padding: 10px 15px;
    display: block;
    color: #0a4c86;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}
@media (min-width: 750px) {
    .dashboard-nav ul li a {
        padding: 15px 20px;
   }
}
@media (min-width: 1200px) {
    .dashboard-nav ul li a {
        padding-right: 28px;
        padding-left: 28px;
   }
}
.dashboard-nav ul li a:hover {
    background-color: rgba(128, 139, 150, 0.8);
    color: white;
}

.dashboard-nav ul li.active  {
    background: #faf8f5;
    outline: none;
    text-decoration: none; 
    color: #0a4c86;
}
