.dashboard-nav {
    position: relative;
}
.dashboard-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    background: #edece9;
}

